/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #444444;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  width: 100%;
  color: #999999;
  font-size: 0.9rem;
  line-height: 1.35em;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #999999;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
}

.font-weight-strong {
  font-weight: 600 !important;
}

/* --------- asc styles --------- */
#background-wrap {
  background: #1b2b44 url(../images/bg-wrap-sm.jpg) no-repeat;
  background-size: cover;
  &.arizona {
    background: #1b2b44 url(../images/bg-wrap-az-sm.jpg) no-repeat center;
    background-size: cover;
  }
  &.california {
    background: #1b2b44 url(../images/bg-wrap-ca-sm.jpg) no-repeat center;
    background-size: cover;
  }
  &.illinois {
    background: #1b2b44 url(../images/bg-wrap-il-sm.jpg) no-repeat center;
    background-size: cover;
  }
  &.texas {
    background: #1b2b44 url(../images/bg-wrap-tx-sm.jpg) no-repeat center;
    background-size: cover;
  }
  &.ron-true {
    background: #1b2b44 url(../images/bg-wrap-ron-sm.jpg) no-repeat;
    background-size: cover;
  }
}
#header-wrap {
  background: rgba(43, 94, 158, 0.8);
}
#support-wrap {
  background: rgba(57, 112, 180, 0.45);
  .login {
    display: none;
  }
}
#support {
  p {
    font-size: 14px;
  }
  a.livechat {
    &:before {
      display: inline-block;
      margin-right: 5px;
      width: inherit;
      height: inherit;
      content: '\e830';
      color: inherit;
      font-family: 'icons';
      font-weight: normal;
      text-align: center;
      font-size: 105%;
      vertical-align: top;
    }
  }
}
#header {
  a.logo {
    width: 50%;
    max-width: 195px;
  }
}
#banner-wrap {
  border-bottom: 7px solid $cta-red;
}
#banner {
  padding: 0 15px 60px 15px;
  // position: relative;
  h1,
  h2,
  h3 {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  }
  .details {
    background: rgba(57, 112, 180, 0.6);
    p {
      font-size: 1.2em;
      line-height: 1.2em;
    }
  }
  .dropdown-list {
    max-width: 300px;
    .dropdown {
      background: #dc1833;
      max-width: 300px;
      font-family: 'Fjalla One', Impact, Verdana;
      font-size: 21px;
      line-height: 40px;
      &:hover,
      &.is-active {
        background: darken(#dc1833, 7%);
      }
      &.large {
        height: auto;
        padding: 5px 15px;
      }
    }
  }
  &.ron {
    background: none;
    h1,
    h2 {
      width: auto;
    }
  }
}
#footer-wrap {
  background: #2b5e9e;
  a {
    color: #fff;
  }
  .dropdown-list.serving .drop {
    border-radius: 3px 3px 0 0;
    border-top: 1px solid #a1c4e5;
    bottom: 38px;
    top: auto;
  }
  .dropdown-list.serving span.dropdown-list:hover,
  .dropdown-list.serving span.dropdown:active,
  .dropdown-list.serving a.dropdown:hover,
  .dropdown.serving a.dropdown-list:active {
    background: none;
  }
  .dropdown-list.serving span.dropdown,
  .dropdown-list.serving a.dropdown {
    background: none;
  }
  .dropdown-list.serving .drop li a {
    color: #333;
  }
}
#footer {
  background: #3970b4;
}

#products-wrap {
  h3 {
    color: #415b8b;
  }
  .product {
    background: #edf2f8;
    margin-top: -30px;
    border-top: 3px solid #415b8b;
  }
  .icon {
    display: inline-block;
    margin-bottom: 10px;
    width: 80px;
    height: 80px;
    background: url('../images/how-it-works-icons.png') no-repeat 0 0 / cover;
    &.included {
      background-position: 0 0;
    }
    &.money {
      background-position: -80px 0;
    }
    &.fast {
      background-position: -160px 0;
    }
  }
  &.ron {
    .icon {
      background: url(../images/ron-steps-icons.png) no-repeat 0 0 / cover;
      &.complete {
        background-position: 0 0;
      }
      &.send {
        background-position: -80px 0;
      }
      &.approved {
        background-position: -160px 0;
      }
    }
  }
}
aside {
  &.inner h3 {
    color: #444;
    border-bottom: 1px dotted #ccc;
  }
  #questions .submit {
    border: 1px solid #ccc;
    min-width: 200px;
  }
}
.cta-btn.pill.normal {
  margin: 0;
  margin-top: 0.65em;
  padding: 9px;
  font-family: 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
  font-size: 1em;
  text-transform: none;
  max-width: 350px;
}
.cta-bar {
  border-top: 1px solid $cta-red;
  border-bottom: 1px solid $cta-red;
}
.action {
  .cta-btn {
    margin-top: -35px;
  }
}
.quote {
  font-size: 1.35em;
  line-height: 1.4em;
  font-family: 'Roboto Slab', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
  border-left: 5px solid #ccc;
}
.state-flag-wrap {
  width: 160px;
  height: 110px;
}
.state-flag {
  width: 150px;
  height: 99px;
  background: #fff url('../images/state-flags.jpg') no-repeat scroll 0 0;
  &.arizona {
    background-position: 0 0;
  }
  &.california {
    background-position: 0 -1000px;
  }
  &.florida {
    background-position: 0 -100px;
  }
  &.illinois {
    background-position: 0 -200px;
  }
  &.indiana {
    background-position: 0 -300px;
  }
  &.kansas {
    background-position: 0 -400px;
  }
  &.michigan {
    background-position: 0 -500px;
  }
  &.missouri {
    background-position: 0 -600px;
  }
  &.north-dakota {
    background-position: 0 -1200px;
  }
  &.pennsylvania {
    background-position: 0 -700px;
  }
  &.tennessee {
    background-position: 0 -1099px;
  }
  &.texas {
    background-position: 0 -800px;
  }
  &.washington {
    background-position: 0 -900px;
  }
}

a.img-hover:hover {
  img {
    opacity: 0.8;
  }
}

.accordion {
  .accordion-toggle {
    color: inherit;
  }
}
.price.new-price {
  text-shadow: none;
  .dollar-sign {
    font-size: 0.5em;
    line-height: 1.5em;
    margin-right: 3px;
    margin-left: 3px;
  }
  sup {
    font-size: 0.5em;
    top: -0.7em;
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #banner {
    .details {
      width: 80%;
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header a.logo {
    width: 50%;
    span.small {
      font-size: 18px;
    }
  }
  #header-wrap {
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 50%;
    span.small {
      font-size: 18px;
    }
  }
  #header #support {
    display: block;
    span.phone {
      display: inline-block;
    }
  }
  #support-wrap .login {
    display: inline-block;
  }
  #background-wrap {
    background: #1b2b44 url(../images/bg-wrap.jpg) no-repeat;
  }
  #background-wrap {
    background: #1b2b44 url(../images/bg-wrap.jpg) no-repeat;
    background-size: cover;
  }
  #background-wrap.arizona {
    background: #1b2b44 url(../images/bg-wrap-az.jpg) no-repeat center;
    background-size: cover;
  }
  #background-wrap.california {
    background: #1b2b44 url(../images/bg-wrap-ca.jpg) no-repeat center;
    background-size: cover;
  }
  #background-wrap.illinois {
    background: #1b2b44 url(../images/bg-wrap-il.jpg) no-repeat center;
    background-size: cover;
  }
  #background-wrap.texas {
    background: #1b2b44 url(../images/bg-wrap-tx.jpg) no-repeat center;
    background-size: cover;
  }
  #background-wrap.ron-true {
    background: #1b2b44 url(../images/bg-wrap-ron.jpg) no-repeat;
    background-size: cover;
  }
  #banner {
    background: transparent url(../images/banner-bg.png) no-repeat right -45px;
    padding-bottom: 75px;
    h1 {
      font-size: 2em;
      width: 70%;
    }
    h2 {
      width: 70%;
      font-size: 1.5em;
      text-align: left;
    }
    .details {
      width: 60%;
    }
    &.naa {
      padding-bottom: 38px;
      background: transparent url(../images/banner-bg.png) no-repeat right 0px;
    }
    &.large-banner {
      background: transparent url(../images/banner-bg.png) no-repeat right 40px;
      padding-bottom: 20px;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #banner {
    h1,
    h2 {
      width: 60%;
    }
  }
}
